body {
    font-family: "Helvetica", sans-serif;
    background-color: #222;
    color: #AAA;
}

.app-container {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}

small {
    font-size: medium;
    display: block;
}

h1, h2 {
    text-align: center;
}

input, button, select {
    font-size: 1em;
    border-radius: 3px;
}

.section {
    margin-top: 15px;
}

.label {
    width: 150px;
    display: inline-block;
    text-align: right;
    margin-right: 5px;
}